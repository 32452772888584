import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  MdAdd,
} from "react-icons/md";
import DevelopmentTable from "views/admin/projects_details/components/DevelopmentTable";
import CheckTable from "views/admin/projects_details/components/CheckTable";
import ColumnsTable from "views/admin/projects_details/components/ColumnsTable";
import ComplexTable from "views/admin/projects_details/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/projects_details/variables/columnsData";
import tableDataDevelopment from "views/admin/projects_details/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/projects_details/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/projects_details/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/projects_details/variables/tableDataComplex.json";
import React from "react";
import Input from "components/fields/InputField"
import Select from "components/fields/Select";
import Button from "components/fields/Button";




export default function ProjectsDetails() {
  const [queryParams, setQueryParams] = useState({});
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    

    const queryObject = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    setQueryParams(queryObject);

   

  }, []);

  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Projects Details {queryParams['id']}
        </Heading>
        <Button disabled leftIcon={<MdAdd />} variant="brand">
          Create New Project
        </Button>{" "}
      </Box>

      <Flex alignItems="start" width="50%" ml="20px" mt="50px">
        <FormControl>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="Search"
            fontWeight="500"
            size="lg"
            borderRadius="10px"
            w="460px"
          />
        </FormControl>
        <Select width="200px"></Select>
      </Flex>

      <ComplexTable
        columnsData={columnsDataComplex}
        tableData={tableDataComplex}
      />
    </Box>
  );
}
