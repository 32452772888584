import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Heading,
  useColorModeValue,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Spinner,
  border,
} from "@chakra-ui/react";
import { MdAdd, MdArrowRightAlt, MdModeEdit } from "react-icons/md";
import Button from "components/fields/Button";
import { useAuth } from "contexts/AuthContext";
import Card from "components/card/Card";
import Projects from "views/admin/home/components/Projects";

export default function ProjectDetails() {
  const { getCustomers } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const localState = useSelector((state) => state.local);
  const dispatch = useDispatch();
    const textColor = useColorModeValue("navy.700", "white");
      const [spinner, setSpinner] = useState(true);


      



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  useEffect(() => {
    var projectId = new URLSearchParams(window.location.search).get(
      "projectId"
    );
    var projects = localState.objects;
    var filter_projects = projects.filter((project) => project.id == projectId);
    if (filter_projects)
      if (filter_projects.length > 0) {
        setProject(filter_projects[0]);
        // setProjects(filter_customers[0].projects);
      }
     setSpinner(false);
    
  }, [useState]);

  const handleEditProject = () => {
    navigate("/onboarding/edit-project?parent=list&projectId="+ project.id); // internal route
  };

  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Project Details
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          rightIcon={<MdArrowRightAlt />}
          variant="brand"
          // onClick={handleCreateNewProject}
        >
          Continue Inspection
        </Button>
      </Box>

      <Flex ml="20px" mt="50px" mb='20px' alignItems={"center"}>
        <Text size="md" fontWeight="bold">
          {project.name}
        </Text>
        <Box
          px="40px"
          py="10px"
          bgColor="#E2E8F0"
          mx="20px"
          fontWeight="bold"
          textColor="#A0AEC0"
        >
          SB721
        </Box>
      </Flex>

      <Card mt="10px" ml="20px" width="90%">
        <Flex display="flex" justifyContent="space-between">
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Name: </Text>
            <Text mt="10px">{project.name}</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Address: </Text>
            <Text width="200px" mt="10px" wordBreak="break-word" overflowWrap="break-word">{project.address}</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Total EEEs</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Balcony</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Stair</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Walkway</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Project Status</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Inspection Day</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Expert</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Text fontWeight={"bold"}>Customer</Text>
            <Text mt="10px">0</Text>
          </Box>
          <Box fontSize={"14px"}>
            <Button style={{border: "solid 1px #eee"}} onClick={handleEditProject}><MdModeEdit/></Button>
          </Box>
        </Flex>
      </Card>

      <Tabs ml="20px" mt="30px">
        <TabList>
          <Tab>EEE Details</Tab>
          <Tab>Building Details</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Card p="0" ml="0" width="90%" mt="10px">
              <TableContainer>
                <Table
                  variant="simple"
                  colorScheme="brand"
                  size={{ base: "sm", md: "md", lg: "md" }}
                >
                  <Thead>
                    <Tr>
                      <Th bg="#EBEFFF" textColor={textColor}>
                        EEE ID
                      </Th>
                      <Th
                        bg="#EBEFFF"
                        textColor={textColor}
                        wordBreak="break-word"
                        textAlign={"center"}
                      >
                        Type
                        <Text>Count</Text>
                      </Th>
                      <Th
                        textAlign={"center"}
                        bg="#EBEFFF"
                        textColor={textColor}
                      >
                        Building
                        <Text>Status</Text>
                      </Th>
                      <Th bg="#EBEFFF" textColor={textColor}>
                        Floor
                        <Text>Date</Text>
                      </Th>
                      <Th bg="#EBEFFF" textColor={textColor}>
                        Unit
                      </Th>
                      <Th bg="#EBEFFF" textColor={textColor}>
                        Inspection Status
                      </Th>
                      <Th bg="#EBEFFF" textColor={textColor}>
                        Inspection Result
                      </Th>
                      <Th bg="#EBEFFF" textColor={textColor}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {!spinner ? (
                      projects.map((item) => (
                        <Tr key={item.id}>
                          <Td>
                            <Text fontWeight={"bold"}> {item.name}</Text>
                            <Flex mt="8px">
                              <MdLocationPin />
                              <Text fontSize={"14px"} textColor={secondaryText}>
                                {item.address}
                              </Text>
                            </Flex>
                          </Td>
                          {/* <Td>{item.address}</Td> */}
                          <Td textAlign={"center"}>0</Td>
                          <Td textAlign={"center"}>
                            <ProjectStatusBadge status={item.status} />
                          </Td>
                          <Td>{formatDate(item.created_at)}</Td>
                          <Td>{item.expert.name}</Td>
                          <Td>
                            {item.customer.first_name +
                              " " +
                              item.customer.last_name}
                          </Td>
                          <Td>
                            <Button
                              key={item.id}
                              onClick={() => handleProjectDetails(item.id)}
                              href="#"
                              style={{ textDecoration: "underline" }}
                            >
                              View Details {">"}
                            </Button>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Box m={10} textAlign="center">
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color={textColor}
                          size="lg"
                        />
                      </Box>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          </TabPanel>
          <TabPanel>
            <Card></Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
