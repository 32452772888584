/* eslint-disable */
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  HStack,
  Text,
  useColorModeValue,
  PinInput,
  PinInputField,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";

import { useAuth } from "contexts/AuthContext";

// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { colorMode } = useColorMode();

  //------------------

  const { otp } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleOtp = async (e) => {
    e.preventDefault();
    const success = await otp(pin);
    if (!success) {
      setError("Invalid username or password");
    }
  };

  //------------------

  const [pin, setPin] = useState("");

  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes
  const [isTimeUp, setIsTimeUp] = useState(false);

  const isPinComplete = pin.length === 5;

   useEffect(() => {
     if (timeLeft > 0) {
       const timerId = setInterval(() => {
         setTimeLeft(timeLeft - 1);
       }, 1000);

       return () => clearInterval(timerId);
     } else {
       setIsTimeUp(true); // Set time-up state when time reaches 0
     }
   }, [timeLeft]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Center></Center>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "458px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Verification Code
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              We’ve sent a verification code to your email example@gmail.com
            </Text>
          </Box>
          <form onSubmit={handleOtp}>
            <Flex my={5} alignItems="center" justifyContent="center">
              <HStack>
                <PinInput value={pin} onChange={setPin}>
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>
            </Flex>

            <Flex justifyContent="space-evenly" align="center" mb="24px">
              <Text>Time Left: {formatTime(timeLeft)}</Text>
            </Flex>
            <CButton
              isDisabled={!isPinComplete || isTimeUp}
              fontSize="base"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
            >
              Verify
            </CButton>
          </form>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="900" fontSize="16px">
              Email was not correct?
              <NavLink to="/auth/sign-in">
                <Text color="blue" as="span" ms="5px" fontWeight="900">
                  Edit Email
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
