import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { loginUser, registerUser, otpUser } from "services/userService"; // Import the service
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, sessionOn, logoutDone } from "store/userActions"; 


// Create Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
  

    useEffect(() => {
  
    }, []);

    const login = async (username, password) => {
        try {
            const data = await loginUser(username, password);

            if (data.access_token) {
              setIsAuthenticated(true);

                dispatch(
                  loginSuccess({
                    isAuthenticated: true,
                    token: data.access_token,
                    username: username,
                  })
                )
              navigate("/admin/home");

              return true;
            }
            return false;
        } catch (error) {
            // Handle login failure
            return false;
        }
    };

    const otp = async (code) => {
      try {
        const data = await otpUser(code);

        if (data.access_token) {
          setIsAuthenticated(true);

          dispatch(
            loginSuccess({
              isAuthenticated: true,
              token: data.access_token,
              username: username,
            })
          );
          navigate("/admin/home");

          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        return false;
      }
    };

    const register = async (username, password) => {
      try {
        const data = await registerUser(username, password);
        console.log(data);

        if (data) {         
          navigate("/auth/otp");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        navigate("/auth/otp");
        return false;
      }
    };

    const logout = () => {
        setIsAuthenticated(false);
        dispatch(logoutDone());

        navigate('/auth/sign-out');
    };

    return (
      <AuthContext.Provider
        value={{ isAuthenticated, login, logout, register, otp }}
      >
        {children}
      </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
