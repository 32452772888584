/* eslint-disable */
import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineGroup,
  MdVerifiedUser,
  MdOutlineViewList,
  MdOutlineSupervisedUserCircle,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import HomeDashboard from "views/admin/home";
import Wizard from "views/admin/wizard";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Projects from "views/admin/projects";
import Experts from "views/admin/projects/experts";
import Customers from "views/admin/projects/customers";
import CustomerDetails from "views/admin/projects/customer_details";
import ProjectDetails from "views/admin/projects/project_details";
import RTL from "views/admin/rtl";

// Auth Imports
import SignOut from "views/auth/signout";
import SignInCentered from "views/auth/signIn";
import ForgetPassword from "views/auth/signIn/fortgetPassword";
import NewPassword from "views/auth/signIn/new_password";
import SignUp from "views/auth/signUp";
import GoogleSignInCallback from "views/auth/signIn/google_signin";
import GoogleSignUpCallback from "views/auth/signUp/google_signup";
import Accept_Invitee from "views/auth/signUp/signup_invitee";
import Otp from "views/auth/signUp/otp";
import AddCompany from "views/admin/company/addcompany";
import NewProject from "views/admin/company/new_project";
import EditProject from "views/admin/projects/edit_project";
import NewCustomer from "views/admin/company/new_customer";
import StartInspection from "views/admin/company/start-inspection";
import InviteExpret from "views/admin/company/inviteexperts";


const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    component: <HomeDashboard />,
  },
  {
    name: "Wizard",
    layout: "/admin",
    path: "/wizard",
    icon: <Icon as={MdHome} width="30px" height="30px" color="inherit" />,
    nolink: true,
    component: <Wizard />,
  },
  {
    name: "Projects",
    layout: "/admin",
    icon: (
      <Icon as={MdOutlineViewList} width="30px" height="30px" color="inherit" />
    ),
    mobile_icon: <MdOutlineViewList size="28px" color="#203764" />,
    path: "/projects",

    component: <Projects />,
  },

  {
    name: "Customers",
    layout: "/admin",
    icon: (
      <Icon as={MdOutlineGroup} width="30px" height="30px" color="inherit" />
    ),
    mobile_icon: <MdOutlineGroup size="28px" color="#203764" />,
    path: "/customers",
    component: <Customers />,
  },
  {
    name: "Customer Details",
    layout: "/admin",
    icon: (
      <Icon as={MdOutlineGroup} width="30px" height="30px" color="inherit" />
    ),
    mobile_icon: <MdOutlineGroup size="28px" color="#203764" />,
    path: "/customer-details",
    nolink: true,
    component: <CustomerDetails />,
  },
  {
    name: "Experts",
    layout: "/admin",
    icon: (
      <Icon as={MdOutlineGroup} width="30px" height="30px" color="inherit" />
    ),
    mobile_icon: <MdOutlineGroup size="28px" color="#203764" />,
    path: "/experts",
    component: <Experts />,
  },
  {
    name: "Projects Details",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/project-details",
    nolink: true,
    component: <ProjectDetails />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    nolink: true,
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: "Forget Password",
    layout: "/auth",
    nolink: true,
    path: "/forget-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <ForgetPassword />,
  },
  {
    name: "New Password",
    layout: "/auth",
    nolink: true,
    path: "/new_password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <NewPassword />,
  },
  {
    name: "Profile",
    layout: "/admin",
    icon: (
      <Icon as={MdVerifiedUser} width="20px" height="20px" color="inherit" />
    ),
    mobile_icon: <MdOutlineSupervisedUserCircle size="28px" color="#203764" />,
    path: "/profile",
    mobileOnly: true,
    component: <Projects />,
  },
  {
    name: "[Sign Out]",
    layout: "/auth",
    path: "/sign-out",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    desktopOnly: true,
    component: <SignOut />,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <SignUp />,
  },
  {
    name: "Sign In With Google",
    layout: "/auth",
    path: "/sign-in/google",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <GoogleSignInCallback />,
  },

  {
    name: "Sign Up Invitee",
    layout: "/auth",
    path: "/signup-invitee",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <Accept_Invitee />,
  },
  {
    name: "OTP",
    layout: "/auth",
    path: "/otp",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <Otp />,
  },
  {
    name: "Add Company",
    layout: "/onboarding",
    path: "/addcompany",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <AddCompany />,
  },
  {
    name: "New Project",
    layout: "/onboarding",
    path: "/new-project",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <NewProject />,
  },
  {
    name: "Edit Project",
    layout: "/onboarding",
    path: "/edit-project",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <EditProject />,
  },
  {
    name: "New Customer",
    layout: "/onboarding",
    path: "/new-customer",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <NewCustomer />,
  },
  {
    name: "Start Inspection",
    layout: "/onboarding",
    path: "/start-inspection",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <StartInspection />,
  },
  {
    name: "Invite Expert Company",
    layout: "/onboarding",
    path: "/inviteexperts",
    icon: <Icon as={MdLock} width="30px" height="30px" color="inherit" />,
    mobile_icon: <MdHome size="28px" color="#203764" />,
    nolink: true,
    component: <InviteExpret />,
  },
  // {
  //   name: "Set 1",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Set 2",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Set 3",
  //   layout: "/admin",
  //   path: "/profile2",
  //   icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //   component: <Profile />,
  // },
];

export default routes;
