// Chakra imports
import { Text, useColorModeValue, SimpleGrid, Box } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/home/components/Project";

export default function Projects(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 2px 2px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Box mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="10px"
        mb="4px"
      >
        Recently Updated
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Here you can find more details about your projects. Keep you user
        engaged by providing meaningful information.
      </Text> */}
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "3xl": 6 }}
        gap="10px"
        mb="20px"
      >
        <Project
          boxShadow={cardShadow}
          mb="20px"
          ranking="1"
          link="#"
          title="MacDonald"
          status="Inspection"
        />
        <Project
          boxShadow={cardShadow}
          mb="20px"
          ranking="2"
          link="#"
          title="House of Valy"
          status="Evaluation"
        />
        <Project
          boxShadow={cardShadow}
          ranking="3"
          link="#"
          title="Birch Haven"
          status="Evaluation"
        />
        <Project
          boxShadow={cardShadow}
          mb="20px"
          ranking="1"
          link="#"
          title="Willow Brook"
          status="Inspection"
        />
        <Project
          boxShadow={cardShadow}
          mb="20px"
          ranking="2"
          link="#"
          title="Maple Gorve"
          status="Complete"
        />
        <Project
          boxShadow={cardShadow}
          ranking="3"
          link="#"
          title="Sunny Hollow"
          status="Complete"
        />
      </SimpleGrid>
    </Box>
  );
}
