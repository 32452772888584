// src/components/ProtectedRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => { 
  const userState = useSelector((state) => state.auth);

  if (!userState) return <Navigate to="/auth/sign-in" />;
  if (!userState.user) return <Navigate to="/auth/sign-in" />;
  if (!userState.user.user.id) return <Navigate to="/auth/sign-in" />
  return element;

  return <Navigate to="/auth/sign-in" />;

};

export default ProtectedRoute;
