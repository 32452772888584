// src/config/apiConfig.js
export const API_BASE_URL = "https://api.repolet.dev/api/v1";

export const API_URLS = {
  login: `${API_BASE_URL}/login`,
  logout: `${API_BASE_URL}/logout`,
  register: `${API_BASE_URL}/register`,
  otp: `${API_BASE_URL}/otp`,
};

