// Chakra imports
// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,Box, Icon
} from "@chakra-ui/react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdListAlt,
  MdBugReport,
  MdFileCopy,
  MdFormatListBulleted,
} from "react-icons/md";
// Custom components
import Card from "components/card/Card.js";
// Custom icons
import React from "react";

export default function Default(props) {
  const { startContent, endContent, name, growth, value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.800";
  const cardBg = "#E4EAF6";
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Card py="15px" backgroundColor={cardBg}>
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "start" }}
        justify={{ base: "center", xl: "center" }}
        alignItems="center"
        justifyContent="space-between"
        flexDirection={{ base: "column", md: "row" }}
        textAlign={{ base: "center", md: "left" }}
        px={{ base: "5px", md: "30px" }}
      >
        <Box bgColor={boxBg} p="10px" borderRadius="50%" pt="15px" px="12px">
          <Icon w="32px" h="32px" as={MdListAlt} color={brandColor} />
        </Box>
        <Box>
          <Text
            color={textColorSecondary}
            fontSize={{
              base: "md",
            }}
          >
            {name}
          </Text>
        </Box>
        <Box>
          <Text
            color={textColor}
            fontWeight="bold"
            fontSize={{
              base: "3xl",
            }}
          >
            {value}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}
