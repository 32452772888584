
import "./assets/css/App.css";
import { Routes, Route, Navigate } from "react-router-dom";


import {} from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import {
  ChakraProvider,
  // extendTheme
} from "@chakra-ui/react";
import initialTheme from "./theme/theme"; //  { themeGreen }
import { useState } from "react";

import { AuthProvider } from "contexts/AuthContext";
import ProtectedRoute from "components/HOC/ProtectedRoute";


export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
 
        
  return (
    <AuthProvider>
      <ChakraProvider theme={currentTheme}>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route
            path="admin/*"
            element={
              <ProtectedRoute
                element={
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                }
              />
            }
          />
          <Route path="/" element={<Navigate to="/admin" replace />} />
        </Routes>
        
      </ChakraProvider>
    </AuthProvider>
  );
}
