// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Button from "components/fields/Button";
import { MdAdd, MdNotificationsActive } from "react-icons/md";


// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";

export default function ActionCard(props) {
  const { image, name, author, bidders, button_name, link } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const navigate = useNavigate();

   

  const handleClick =  (e) => {
    navigate(link);
  };


  return (
    <Card p="20px">
      <Flex direction={{ base: "column" }} justify="center">
        <Box mb={{ base: "20px", "2xl": "20px" }} position="relative">
          <Image
            margin="0 auto"
            src={image}
            w={{ base: "160px", "3xl": "100%" }}
            h={{ base: "160px", "3xl": "100%" }}
            borderRadius="20px"
          />
        </Box>
        <Flex flexDirection="column" justify="space-between" h="100%">
          <Flex
            justify="space-between"
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb="auto"
          >
            <Flex direction="column" textAlign="center" width="100%">
              <Text
                color={textColor}
                fontSize={{
                  base: "2xl",
                  md: "xl",
                  lg: "xl",
                  xl: "lg",
                  "2xl": "2xl",
                  "3xl": "lg",
                }}
                mb="5px"
                fontWeight="400"
                me="14px"
              >
                {name}
              </Text>
              <Text
                color="secondaryGray.600"
                fontSize={{
                  base: "sm",
                }}
                fontWeight="400"
                me="14px"
              >
                {author}
              </Text>
            </Flex>
            <AvatarGroup
              max={3}
              color={textColorBid}
              size="sm"
              mt={{
                base: "0px",
                md: "10px",
                lg: "0px",
                xl: "10px",
                "2xl": "0px",
              }}
              fontSize="12px"
            >
              {bidders.map((avt, key) => (
                <Avatar key={key} src={avt} />
              ))}
            </AvatarGroup>
          </Flex>
          {button_name != "" ? (
            <Flex
              align="start"
              justify="space-between"
              direction={{
                base: "row",
                md: "column",
                lg: "row",
                xl: "column",
                "2xl": "row",
              }}
              mt="25px"
            >
              <Button
                bgColor="#203764"
                width="100%"
                textColor="#fff"
                leftIcon={<MdAdd />}
                onClick={handleClick}
              >
                {button_name}
              </Button>
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </Card>
  );
}
