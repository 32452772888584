// src/pages/LogOffPage.js
import React, { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Spinner, Text } from "@chakra-ui/react";

const LogOffPage = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Log the user out and wait for 2 seconds before redirecting
    logout();
    navigate("/auth/sign-in");
    // const timer = setTimeout(() => {
    //   navigate("/auth/sign-in");
    // }, 2000); // 2000ms = 2 seconds

    // Cleanup the timeout when the component unmounts
    //return () => clearTimeout(timer);
  }, [logout, navigate]);

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
      flexDirection="column"
    >
      <Spinner size="xl" mb={4} />
      <Text>Logging you off...</Text>
    </Box>
  );
};

export default LogOffPage;
