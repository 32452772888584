/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";


// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Link,
  InputRightElement,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
} from "@chakra-ui/react";
import { MdArrowBackIosNew } from "react-icons/md";


// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import Upload from "views/admin/company/components/Upload";
import Select from "components/fields/Select";



import { useAuth } from "contexts/AuthContext";

// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Card from "components/card/Card";

function NewProject() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
 
  const brandColor = useColorModeValue("brand.400", "white");
  var noCompanyExpert = true;

  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const { colorMode } = useColorMode();

  //------------------

  const { getexperts, addProject } = useAuth();
  const [project_name, setProject_name] = useState("");
  const [project_address, setProject_address] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const inputFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeButton, setActiveButton] = useState(null); 
  const [experts, setExperts] = useState(null);
  const userState = useSelector((state) => state);
  const [selectedItem, setSelectedItem] = useState(null);
  const localState = useSelector((state) => state.local);


  
   const steps = [
     { title: "Customer Info", description: "" },
     { title: "Project Details", description: "" },
     { title: "Inspection", description: "" },
   ];

   const { activeStep } = useSteps({
     index: 1,
     count: steps.length,
   });

   useEffect(() => {
    setExperts(false);
    const getExperts = async (e) => {
      const experts_data = await getexperts();
      setExperts(experts_data);
      setSelectedItem(experts_data[0]);
      console.log(experts_data);
    };

    getExperts();
   },[]);

  const handleAddProject = async (e) => {
    e.preventDefault();
    
    const success = await addProject(
      project_address,
      project_name,
      experts ? selectedItem.value : null,
      "_description",
      localState.objects.customer_id,
      localState.objects.first_name,
      localState.objects.last_name,
      localState.objects.phone,
      localState.objects.email
    );

    if (!success) {
      setError("Error in add projejct");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value); // Update the state with the selected value
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        ma
        ml={{ base: "20px", lg: "50px" }}
        mt={{ base: "0px", lg: "50px" }}
        style={{ alignItems: "flex-end" }}
      >
        <Link href="/">
          <Icon
            as={MdArrowBackIosNew}
            w="20px"
            h="20px"
            color="#000"
            marginRight={5}
          />
        </Link>

        <Text fontWeight={500} fontSize={22}>
          Add New Project
        </Text>
      </Flex>
      <Flex
        //  w={{ base: "100%", md: "800px" }}
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "7vh" }}
        flexDirection="column"
      >
        <Card
          marginTop="0"
          mb="20px"
          w={{ base: "95%", md: "800px" }}
          mx={{ base: "10px", md: "60px" }}
        >
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <VStack>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </VStack>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Card>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Project Details
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter project details below.
            </Text>
          </Box>
          <form onSubmit={handleAddProject}>
            <VStack spacing={4} alignItems="start">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                
                htmlFor="project_name"
              >
                Project Name <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Write project address here"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={project_name}
                id="project_name"
                name="project_name"
                onChange={(e) => setProject_name(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="0px"
                htmlFor="project_address"
              >
                Project Address <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Write project address here"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={project_address}
                id="project_address"
                name="project_address"
                onChange={(e) => setProject_address(e.target.value)}
              />

              {!experts ? (
                <Flex
                  style={{ marginBottom: "30px" }}
                  bgColor="#E4EAF6"
                  p="30px"
                  borderRadius="15px"
                  marginBottom="30px"
                >
                  <Text>
                    Your company does not have any Experts yet. We have
                    auto-assigned you as the Expert for this project. You can
                    change it later from Projects.
                  </Text>
                </Flex>
              ) : (
                <Box>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="username"
                  >
                    <VStack alignItems="start">
                      <Text>Assign an Expert (Optional)</Text>
                      <Text fontSize="14px" color={textColorSecondary}>
                        You can add or change the expert later in project
                        details.
                      </Text>
                    </VStack>
                  </FormLabel>

                  <Box justifyContent="space-between" pb="30px" width="100%">
                    <Select
                      width="100%"
                      textColor={textColor}
                      value={selectedItem}
                      onChange={handleSelectChange}
                    >
                      {experts.map((item, index) => (
                        <option key={index} value={item}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Box>
              )}

              {/* <input
                accept="image/*"
                ref={inputFileRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleFileChange}
              /> */}
            </VStack>

            <HStack>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="30%"
                h="50"
                type="button"
                bgColor="#fff"
                textColor="#203764"
                border="solid .5px #203764"
                onClick={handleCancelClick}
              >
                Cancel
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="70%"
                h="50"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                onClick={handleAddProject}
              >
                Save and Continue
              </CButton>
            </HStack>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default NewProject;
