// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
// Assets
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/company/components/Dropzone";

export default function Upload(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColorSecondary = "gray.400";
  return (
     <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
        <Dropzone
         w="100px"
          h="100px"
          ml='15px'
          maxH={{ base: "80%", lg: "80%", "2xl": "100%" }}
          minH={{ base: "60%", lg: "50%", "2xl": "100%" }}
          
          content={
            <Box>
              <Icon as={MdUpload} w='20px' h='20px' color={brandColor} />
              <Flex justify='center' mx='auto' mb='12px'>
                <Text fontSize='md' fontWeight='700' color={brandColor}>
                  Upload
                </Text>
              </Flex>
              {/* <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                PNG, JPG and GIF files are allowed
              </Text> */}
            </Box>
          }
        />
       
      </Flex>
  );
}
