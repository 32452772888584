import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Flex,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  Link,
  Text,
  useColorModeValue,
  VStack,
  HStack,
  Button,
} from "@chakra-ui/react";
import { MdDeleteForever, MdArrowBackIosNew } from "react-icons/md";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import Select from "components/fields/Select";
import { useAuth } from "contexts/AuthContext";
import illustration from "assets/img/auth/auth.png";


function EditProject() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const { colorMode } = useColorMode();

  //------------------

  const { getexperts, getCustomers, editProject } = useAuth();
  const [project_name, setProject_name] = useState("");
  const [project_address, setProject_address] = useState("");
  const [error, setError] = useState("");
  const [experts, setExperts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [project, setProject] = useState({ name: "", address: "" });
  const [selectedExpert, setSelectedExpert] = useState({});
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const localState = useSelector((state) => state.local);

   useEffect(() => {
     var projectId = new URLSearchParams(window.location.search).get(
       "projectId"
     );
     var parent = new URLSearchParams(window.location.search).get(
       "parent"
     );

     setExperts(false);
     const getExperts = async (e) => {
       const experts_data = await getexperts();
       setExperts(experts_data);
     };
     getExperts();

     const getCustomer = async (e) => {
       const customers_data = await getCustomers();
       setCustomers(customers_data.customers);
     };
     getCustomer();

     var projects = localState.objects;
     var filter_projects = projects.filter(
       (project) => project.id == projectId
     );

     if (filter_projects)
       if (filter_projects.length > 0) {
         setProject(filter_projects[0]);
         setSelectedExpert(filter_projects[0].expert.id);
         setProject_name(filter_projects[0].name);
         setProject_address(filter_projects[0].address);
         setSelectedCustomer(filter_projects[0].customer.id);
       }
   }, [useState]);

  const handleEditProject = async (e) => {
    e.preventDefault();

    const success = await editProject(
      project.id,
      project_address,
      project_name,
      experts ? selectedExpert : null,
      "_description",
      customers ? selectedCustomer : null
    );

    if (!success) {
      setError("Error in edit project " + project.id);
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSelectExpertChange = (event) => {
    setSelectedExpert(event.target.value);
  };

  const handleSelectCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        ma
        ml={{ base: "20px", lg: "50px" }}
        mt={{ base: "0px", lg: "50px" }}
        style={{ alignItems: "flex-end" }}
        justifyContent={"space-between"}
      >
        <Link href="/">
          <Flex alignItems={"center"}>
            <Icon
              as={MdArrowBackIosNew}
              w="20px"
              h="20px"
              color="#000"
              marginRight={5}
            />
            <Text fontWeight={500} fontSize={22}>
              Edit Project
            </Text>
          </Flex>
        </Link>
        <Button>
          <Flex alignItems={"center"} justifyContent={"space-evenly"}>
            <Text fontWeight={100} fontSize={16} color={"red"}>
              Delete Project
            </Text>
            <Icon
              as={MdDeleteForever}
              w="20px"
              h="20px"
              color="red"
              marginLeft={5}
            />
          </Flex>
        </Button>
      </Flex>
      <Flex
        //  w={{ base: "100%", md: "800px" }}
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "7vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Project Details
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Edit your project Information and change th expert or customer
              within this page.
            </Text>
          </Box>
          <form onSubmit={handleEditProject}>
            <VStack spacing={4} alignItems="start">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                htmlFor="project_name"
              >
                Project Name <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Write project address here"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={project_name}
                id="project_name"
                name="project_name"
                onChange={(e) => setProject_name(e.target.value)}
              />
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="0px"
                htmlFor="project_address"
              >
                Project Address <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Write project address here"
                mb="24px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={project_address}
                id="project_address"
                name="project_address"
                onChange={(e) => setProject_address(e.target.value)}
              />

              {!experts ? (
                <Flex
                  style={{ marginBottom: "10px" }}
                  bgColor="#E4EAF6"
                  p="30px"
                  borderRadius="15px"
                >
                  <Text>
                    Your company does not have any Experts yet. We have
                    auto-assigned you as the Expert for this project. You can
                    change it later from Projects.
                  </Text>
                </Flex>
              ) : (
                <Box>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="username"
                  >
                    <VStack alignItems="start">
                      <Text>Assign an Expert (Optional)</Text>
                    </VStack>
                  </FormLabel>

                  <Box justifyContent="space-between" pb="10px" width="100%">
                    <Select
                      width="100%"
                      textColor={textColor}
                      value={selectedExpert}
                      onChange={handleSelectExpertChange}
                    >
                      {experts.map((expert, index) => (
                        <option key={index} value={expert.id}>
                          {expert.name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Box>
              )}

              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                  htmlFor="username"
                >
                  <VStack alignItems="start">
                    <Text>Customer</Text>
                  </VStack>
                </FormLabel>

                <Box justifyContent="space-between" pb="30px" width="100%">
                  <Select
                    width="100%"
                    textColor={textColor}
                    value={selectedCustomer}
                    onChange={handleSelectCustomerChange}
                  >
                    {customers.map((customer, index) => (
                      <option key={index} value={customer.id}>
                        {customer.first_name + " " + customer.last_name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Box>
            </VStack>

            <HStack>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="30%"
                h="50"
                type="button"
                bgColor="#fff"
                textColor="#203764"
                border="solid .5px #203764"
                onClick={handleCancelClick}
              >
                Cancel
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="70%"
                h="50"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                onClick={handleEditProject}
              >
                Save and Continue
              </CButton>
            </HStack>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default EditProject;
