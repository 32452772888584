// src/services/userService.js
import { API_URLS } from "config/apiConfig";

export const loginUser = async (username, password) => {
  try {

    const _body = JSON.stringify({
      email: username,
      password: password,
    });

    const response = await fetch(API_URLS.login, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const otpUser = async (code) => {
  try {
    const _body = JSON.stringify({
      code: code,
    });

    const response = await fetch(API_URLS.otp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("OTP failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Login error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const registerUser = async (username, password) => {
  try {
    const _body = JSON.stringify({
      name: username,
      email: username,
      password: password,
    });

    const response = await fetch(API_URLS.register, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Register failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Register error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};
