import { Box, Button, useStyleConfig } from "@chakra-ui/react";
function CButton(props) {
  const { variant, children, bgColor, textColor, ...rest } = props;
  const styles = useStyleConfig("Card", { variant });

  return (
    <Button
      _hover={{}}
      backgroundColor={bgColor}
      color={textColor}
      px="40px"
      py="25px"
      borderRadius="5px"
      {...rest}
    >
      {children}
    </Button>
  );
}

export default CButton;
