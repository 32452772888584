// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Badge,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
// Assets
import { MdEdit } from "react-icons/md";

export default function Project(props) {
  const { title, status, ranking, link, image, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        {/* <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' /> */}
        <Box mt={{ base: "10px", md: "0" }} width="100%">
          <Box display="flex" width="100%" justifyContent="space-between">
            <Text
              color={textColorPrimary}
              fontWeight="900"
              fontSize="md"
              mb="4px"
            >
              {title}
            </Text>
            <Badge
              colorScheme={
                status === "Inspection"
                  ? "orange"
                  : status === "Evaluation"
                  ? "blue"
                  : status === "Complete"
                  ? "green"
                  : null
              }
            >
              {" "}
              {status}
            </Badge>
          </Box>
          <Flex>
            <Text
              fontWeight="500"
              color={textColorSecondary}
              fontSize="sm"
              me="4px"
            >
              Address:
            </Text>
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
            >
              1600 Amphhltheatre Parkway...
            </Text>
          </Flex>
          <Flex>
            <Text
              fontWeight="500"
              color={textColorSecondary}
              fontSize="sm"
              me="4px"
            >
              Update:
            </Text>
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
            >
              07/03/2024
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
}
