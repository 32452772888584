import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  login_service,
  register_service,
  otp_verify_service,
  otp_verify_password_service,
  forget_password_service,
  new_password_service,
  signup_expert_service,
  get_user_service,
  google_signIn_service,
  google_signUp_service,
} from "services/userService";
import {
  onboarding_company_service,
  invite_expert_service,
  company_expert_service,
  add_project_service,
  edit_project_service,
  company_project_service,
  company_customer_service,
} from "services/companyService";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, setUser, logoutDone, userInfo } from "store/userActions";

// Create Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [email, setEmail] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth);

  useEffect(() => {}, []);

  const login = async (username, password) => {
    try {
      const response = await login_service(username, password);

      if (response.success) {
        var userInfo = await getuser(response.data.access_token);
        if (userInfo.user) {
          navigate("/admin/home");
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const login_google = async (code) => {
    try {
      //const response = await google_signIn_service(code);

      if (code) {
        var userInfo = await getuser(code);
        if (userInfo.user) {
          navigate("/admin/home");
        }
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  const register_google = async (code) => {
    try {
      //const response = await google_signUp_service(code);

      if (code) {
        var userInfo = await getuser(code);
        if (userInfo.user) {
          navigate("/onboarding/addcompany");
        }
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const forgetPassword_sendEmail = async (username) => {
    try {
      const data = await forget_password_service(username);

      if (data.success) {
        dispatch(
          loginSuccess({
            isAuthenticated: false,
            token: "",
            username: username,
          })
        );
        navigate("/auth/otp?md=3droWssAp_wEn");

        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const otp_verify = async (code, email) => {
    try {
      const response = await otp_verify_service(code, email);

      if (response.success) {
        var userInfo = await getuser(response.data.token);
        if (userInfo.user) {
          navigate("/onboarding/addcompany");
        }
        return true;
      }

      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const otp_verify_newPassword = async (code, email) => {
    try {
      const response = await otp_verify_password_service(code, email);

      if (response.success) {
        //setIsAuthenticated(true);

        dispatch(
          setUser({
            isAuthenticated: false,
            username: email,
            otp: code,
          })
        );
        navigate("/auth/new_password");

        return true;
      }

      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const registerUserCompany = async (
    username,
    password,
    name,
    company_name
  ) => {
    try {
      const data = await register_service(
        username,
        password,
        name,
        company_name
      );

      if (data.success) {
        dispatch(
          setUser({
            username: username,
            company: company_name,
          })
        );

        navigate("/auth/otp");
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure
      navigate("/auth/otp");
      return false;
    }
  };

  const register_expert = async (name, username, password, token) => {
    try {
      const data = await signup_expert_service(username, name, password, token);

      if (data.success) {
        dispatch(
          setUser({
            token: token,
            username: username,
          })
        );

        navigate("/auth/sign-in");
        return true;
      }
      return false;
    } catch (error) {
      // Handle login failure

      return false;
    }
  };

  const newPassword = async (password, confirm_password) => {
    try {
      var otp = userState.user.otp;
      var email = userState.user.username;

      const data = await new_password_service(
        email,
        otp,
        password,
        confirm_password
      );
      console.log(data);

      if (data.success) {
        navigate("/auth/sign-in");
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const logoutUser = () => {
    setIsAuthenticated(false);
    dispatch(logoutDone());
    navigate("/auth/sign-in");
    return false;
  };

  //-------- Company Context ---------- will put in the separated file

  const onboarding = async (name, phone_number, address, logo, description) => {
    if (userState)
      try {
        const data = await onboarding_company_service(
          name,
          phone_number,
          address,
          logo,
          description,
          userState.user.token
        );

        if (data.success) {
          navigate("/onboarding/inviteexperts");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const inviteExpert = async (emails) => {
    if (userState)
      try {
        const data = await invite_expert_service(emails, userState.user.token);

        if (data.success) {
          navigate("/admin/home");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const getuser = async (token) => {
    try {
      const response = await get_user_service(token);

      if (response.success) {
        dispatch(userInfo(response.data, token));

        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getexperts = async () => {
    try {
      const response = await company_expert_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getProjects = async () => {
    try {
      const response = await company_project_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const getCustomers = async () => {
    try {
      const response = await company_customer_service(userState.user.token);

      if (response.success) {
        return response.data;
      }
      return false;
    } catch (error) {
      // Handle login failure
      return false;
    }
  };

  const addProject = async (
    project_address,
    project_name,
    expert_id,
    description,
    customer_id,
    first_name,
    last_name,
    phone,
    email
  ) => {
    if (userState)
      try {
        const data = await add_project_service(
          project_address,
          project_name,
          expert_id,
          description,
          customer_id,
          first_name,
          last_name,
          phone,
          email,
          userState.user.token
        );

        if (data.success) {
          navigate("/onboarding/start-inspection");
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };

  const editProject = async (
    project_id,
    project_address,
    project_name,
    expert_id,
    description,
    customer_id
  ) => {
    if (userState)
      try {
        const data = await edit_project_service(
          project_id,
          project_address,
          project_name,
          expert_id,
          description,
          customer_id,
          userState.user.token
        );

        if (data.success) {
          getuser(userState.user.token);
          navigate("/admin/project-details?projectId=" + project_id);
          return true;
        }
        return false;
      } catch (error) {
        // Handle login failure
        console.log(error);
        return false;
      }
  };



  //-----------------------------------------------------------------

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logoutUser,
        registerUserCompany,
        otp_verify,
        forgetPassword_sendEmail,
        otp_verify_newPassword,
        newPassword,
        onboarding,
        register_expert,
        inviteExpert,
        getuser,
        login_google,
        register_google,
        getexperts,
        addProject,
        editProject,
        getProjects,
        getCustomers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
