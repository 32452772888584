
import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  HStack,
  VStack,
  Text,
  Flex,
  Heading,
} from "@chakra-ui/react";
import {
  MdAdd,
} from "react-icons/md";
import DevelopmentTable from "views/admin/projects/components/DevelopmentTable";
import CheckTable from "views/admin/projects/components/CheckTable";
import ColumnsTable from "views/admin/projects/components/ColumnsTable";
import ComplexTable from "views/admin/projects/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/projects/variables/columnsData";
import tableDataDevelopment from "views/admin/projects/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/projects/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/projects/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/projects/variables/tableDataComplex.json";
import React from "react";
import Input from "components/fields/InputField"
import Select from "components/fields/Select";
import Button from "components/fields/Button";


export default function Settings() {
  // Chakra Color Mode
  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Projects
        </Heading>
        <Button leftIcon={<MdAdd />} variant="brand">
          Create New Project
        </Button>{" "}
      </Box>
     
      <Flex alignItems="start" width="50%" ml="20px" mt="50px">
        <FormControl>
          <Input
            isRequired={true}
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="Search"
            fontWeight="500"
            size="lg"
            borderRadius="10px"
            w="460px"
          />
        </FormControl>
        <Select width="200px"></Select>
      </Flex>

      <ComplexTable
        columnsData={columnsDataComplex}
        tableData={tableDataComplex}
      />
    </Box>
  );
}
