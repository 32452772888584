// Chakra imports
import { Text, useColorModeValue, SimpleGrid, Box } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";
import Project2 from "assets/img/profile/Project2.png";
import Project3 from "assets/img/profile/Project3.png";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Project from "views/admin/home/components/Project";

export default function Projects(props) {
  const { projects, title,  ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 2px 2px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };



  return (
    <Box mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="30px"
        mb="20px"
      >
        {title}
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'>
        Here you can find more details about your projects. Keep you user
        engaged by providing meaningful information.
      </Text> */}
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "3xl": 6 }}
        gap="10px"
        mb="20px"
      >
        {projects.map((project) => (
          <Project
            boxShadow={cardShadow}
            mb="10px"
            address={project.address}
            update={formatDate(project.updated_at)}
            title={project.name}
            status={project.status}
            expert={project.expert.name}
          />
        ))}
      </SimpleGrid>
    </Box>
  );
}
