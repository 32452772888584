import {
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  Spacer,
  Icon,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React from "react";
import {
  MdAdd,
  MdOutlineSupervisedUserCircle,
  MdNotifications,
  MdSettings,
} from "react-icons/md";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const brandColor = useColorModeValue("brand.500", "white");

  return (
    <Flex
      justify="center"
      direction="row"
      align="center"
      borderRadius="30px"
      position="relative"
      px="40px"
    >
      <Button
        bg="#C5D2EC"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight="regular"
        borderRadius="10px"
        fontSize="sm"
        mx="auto"
        height="60px"
        width="60px"
      >
        <Icon
          w="32px"
          h="32px"
          as={MdOutlineSupervisedUserCircle}
          color={brandColor}
        />
      </Button>
      <Button
        bg="transparent"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight="regular"
        borderRadius="10px"
        fontSize="sm"
        mx="auto"
        height="60px"
        width="60px"
      >
        <Icon w="32px" h="32px" as={MdSettings} color={brandColor} />
      </Button>
      <Button
        bg="transparent"
        _hover={{ bg: "whiteAlpha.200" }}
        _active={{ bg: "whiteAlpha.100" }}
        mb={{ sm: "16px", xl: "24px" }}
        color={"white"}
        fontWeight="regular"
        borderRadius="10px"
        fontSize="sm"
        mx="auto"
        height="60px"
        width="60px"
      >
        <Icon w="32px" h="32px" as={MdNotifications} color={brandColor} />
      </Button>
    </Flex>
  );
}
