/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Link,
  HStack,
} from "@chakra-ui/react";
import { MdPersonRemove } from "react-icons/md";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import { useAuth } from "contexts/AuthContext";

function InviteExpert() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { colorMode } = useColorMode();
  const [emails, setEmails] = useState([{ value: "" }]);
  const [parent, setParent] = useState(false);
  const userState = useSelector((state) => state.auth);
  const { inviteExpert } = useAuth();

  //------------------

  useEffect(() => {
    const parent = new URLSearchParams(window.location.search).get("parent");
    if (parent == "list") setParent(true);
  });

  const handleInviteExperts = async (e) => {
    const emailData = emails.map((email) => email.value);
    e.preventDefault();
    const success = await inviteExpert(emailData);
    if (!success) {
    }
  };

  const handleNewInvite = () => {
    setEmails([...emails, { value: "" }]);
  };

  const handleInputChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index].value = event.target.value;
    setEmails(newEmails);
  };
  const removeInput = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  //------------------

  return (
    <DefaultAuth>
      <Flex
        // maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "558px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Invite Company Expert
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Invite Experts to your company to assign them to projects.
            </Text>
          </Box>
          <form onSubmit={handleInviteExperts}>
            <VStack spacing={5} alignItems="start">
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="username"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>

              {/* <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="Add Email here"
                mb="20px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={Companyname}
                id="companyname"
                name="companyname"
                onChange={(e) => setCompanyname(e.target.value)}
              /> */}
              {emails.map((email, index) => (
                <Flex width="100%">
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="Add Email here"
                    my="5px"
                    fontWeight="500"
                    w="90%"
                    size="lg"
                    borderRadius="10px"
                    value={email.value}
                    key={index}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <a
                    href="#!"
                    onClick={() => removeInput(index)}
                    style={{
                      color: "gray",
                      display: "block",
                      position: "relative",
                      marginLeft: "-30px",
                      marginTop: "12px",
                      fontSize: "24px",
                    }}
                  >
                    {emails.length > 1 ? (
                      <Icon
                        as={MdPersonRemove}
                        width="20px"
                        height="20px"
                        color="inherit"
                      />
                    ) : null}
                  </a>
                </Flex>
              ))}
              <Box pt="0" pb="30px" textAlign="center" pl="20px">
                <Link onClick={handleNewInvite}>
                  <HStack>
                    <Text fontSize="30px">+</Text>
                    <Text> Add another</Text>
                  </HStack>
                </Link>
              </Box>
            </VStack>

            <CButton
              fontSize="base"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              type="submit"
              bgColor="#203764"
              textColor="#fff"
            >
              Invite to your team
            </CButton>
            <Flex textAlign="center" w="100%">
              {!parent ? (
                <Link
                  href="/admin/home"
                  fontSize="base"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  textColor="#203764"
                >
                  Skip for now
                </Link>
              ) : (
                <Link
                  href="/admin/experts"
                  fontSize="base"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  mb="24px"
                  type="submit"
                  textColor="#203764"
                >
                  Back
                </Link>
              )}
            </Flex>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default InviteExpert;
