import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

function GoogleSignUpCallback() {
  const navigate = useNavigate();
  const { register_google } = useAuth();

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      register_google(code);
    }
  }, [navigate]);

  return <div>Loading...</div>;
}

export default GoogleSignUpCallback;
