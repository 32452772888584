// Action to log in
export const loginSuccess = (user) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: user,
  };
};

export const sessionOn = (time) => {
  return {
    type: "SESSION_ON",
    time: time,
  };
};

// Action to log out
export const logoutDone = () => {
  return {
    type: "LOGOUT",
  };
};
