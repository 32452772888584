// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Grid,
  Stack,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdAdd, MdNotificationsActive } from "react-icons/md";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";


// Assets
import Usa from "assets/img/dashboards/usa.png";
import cover_newproject from "assets/img/project/newproject.png";
import cover_help from "assets/img/dashboards/help.png";
import cover_insight from "assets/img/dashboards/insight.png";

// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Button from "components/fields/Button";
import ActionCard from "components/card/ActionCard";




import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdListAlt,
  MdBugReport,
  MdFileCopy,
  MdFormatListBulleted,
} from "react-icons/md";
import CheckTable from "views/admin/home/components/CheckTable";
import ComplexTable from "views/admin/home/components/ComplexTable";
import DailyTraffic from "views/admin/home/components/DailyTraffic";
import PieCard from "views/admin/home/components/PieCard";
import Tasks from "views/admin/home/components/Tasks";
import TotalSpent from "views/admin/home/components/TotalSpent";
import WeeklyRevenue from "views/admin/home/components/WeeklyRevenue";
import Projects from "views/admin/home/components/Projects";
import { useAuth } from "contexts/AuthContext";


import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const navigate = useNavigate();
  const { getuser, getProjects } = useAuth();
  const [spinner, setSpinner] = useState(true);
  const userState = useSelector((state) => state.auth);
  const [projects, setProjects] = useState([]);

  const handleClick = () => {
    navigate("/onboarding/new-customer"); // internal route
  };

  // useEffect(() => {}, [userState]); // Add dependencies as needed


  useEffect(() => {
    const _getProjects = async (e) => {
      const all_projects = await getProjects();

      var _projects = all_projects.projects;

      setProjects(_projects);
      setSpinner(false);
    };

    _getProjects();
  }, []);

  var isMobile = false;
  if (window.innerWidth < 768) isMobile = true;

  return (
    <Box pt={{ base: "0", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={isMobile ? 8 : 20}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Home
        </Heading>
        {!isMobile ? (
          <Button
            bgColor="#203764"
            textColor="#fff"
            leftIcon={<MdAdd />}
            variant="brand"
            onClick={handleClick}
          >
            Create New Project
          </Button>
        ) : (
          <Icon
            w="32px"
            h="32px"
            as={MdNotificationsActive}
            color={brandColor}
          />
        )}
      </Box>
      <SimpleGrid
        columns={{ base: 3, md: 2, lg: 3, "3xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          name="All Projects"
          value={projects.length}
        />
        <MiniStatistics
          name="Incomplete Projects"
          value={
            projects.filter((project) => project.status != "Completed").length
          }
        />
        <MiniStatistics
          name="Generated Reports"
          value="0"
        />
      </SimpleGrid>

      {!projects ? (
        <Stack spacing={4} display={{ base: "flex", md: "none" }}>
          <ActionCard
            name="Add First Project"
            author="Create a project to start your inspection."
            bidders={[]}
            image={cover_newproject}
            currentbid="0.91 ETH"
            button_name="Create New Project"
            link="/onboarding/new-customer"
          />
          <ActionCard
            name="Learn how Repolet works"
            author="Discover how our service simplifies inspections."
            bidders={[]}
            image={cover_help}
            currentbid="0.91 ETH"
            button_name="Watch Video"
            link="/admin/new-customer"
          />
          <ActionCard
            name="See Insights"
            author="Once you have more projects, insights will be displayed here."
            bidders={[]}
            image={cover_insight}
            currentbid="0.91 ETH"
            button_name=""
          />
        </Stack>
      ) : null}

      {!projects ? (
        <Grid
          templateColumns={{
            base: "repeat(3, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            "3xl": "repeat(6, 1fr)",
          }}
          gap="20px"
          display={{ base: "none", md: "grid" }}
        >
          <ActionCard
            name="Add First Project"
            author="Create a project to start your inspection."
            bidders={[]}
            image={cover_newproject}
            currentbid="0.91 ETH"
            button_name="Create New Project"
            link="/onboarding/new-customer"
          />
          <ActionCard
            name="Learn how Repolet works"
            author="Discover how our service simplifies inspections."
            bidders={[]}
            image={cover_help}
            currentbid="0.91 ETH"
            button_name="Watch Video"
            link="/admin/new-project"
          />
          <ActionCard
            name="See Insights"
            author="Once you have more projects, insights will be displayed here."
            bidders={[]}
            image={cover_insight}
            currentbid="0.91 ETH"
            button_name=""
          />
        </Grid>
      ) : (
        <SimpleGrid>
          <Projects
            gridArea="1 / 2 / 2 / 2"
            title="Recently Added"
            projects={projects}
          />
        </SimpleGrid>
      )}

      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
        <PieCard />
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
