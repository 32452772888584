// src/components/ProtectedRoute.js
import React from "react";
import { useSelector } from "react-redux";

import { Navigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

const ProtectedRoute = ({ element }) => { 
  const userState = useSelector((state) => state.user);
  var isAuthenticated = false;

  if (userState.user)
    if (userState.user.isAuthenticated) {
      isAuthenticated = true;
    } else {
      isAuthenticated = false;
    }
  else isAuthenticated = false;

  return isAuthenticated ? element : <Navigate to="/auth/sign-in" />;

};

export default ProtectedRoute;
