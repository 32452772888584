// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  Heading,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdAdd, MdNotificationsActive } from "react-icons/md";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Button from "components/fields/Button";

import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdListAlt,
  MdBugReport,
  MdFileCopy,
  MdFormatListBulleted,
} from "react-icons/md";
import CheckTable from "views/admin/home/components/CheckTable";
import ComplexTable from "views/admin/home/components/ComplexTable";
import DailyTraffic from "views/admin/home/components/DailyTraffic";
import PieCard from "views/admin/home/components/PieCard";
import Tasks from "views/admin/home/components/Tasks";
import TotalSpent from "views/admin/home/components/TotalSpent";
import WeeklyRevenue from "views/admin/home/components/WeeklyRevenue";
import Projects from "views/admin/home/components/Projects";

import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  var isMobile = false;
  if (window.innerWidth < 768) isMobile = true;

  return (
    <Box pt={{ base: "0", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={isMobile? 8 : 20}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Home
        </Heading>
        {!isMobile ? (
          <Button leftIcon={<MdAdd />} variant="brand">
            Create New Project
          </Button>
        ) : (
          <Icon
            w="32px"
            h="32px"
            as={MdNotificationsActive}
            color={brandColor}
          />
        )}
      </Box>
      <SimpleGrid
        columns={{ base: 3, md: 2, lg: 3, "3xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={!isMobile?
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdListAlt} color={brandColor} />
              }
            />: null
          }
          name="All Projects"
          value="999"
        />
        <MiniStatistics
          startContent={!isMobile?
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="32px"
                  h="32px"
                  as={MdFormatListBulleted}
                  color={brandColor}
                />
              }
            />:null
          }
          name="Incomplete Projects"
          value="999"
        />
        <MiniStatistics
          startContent={!isMobile?
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
              }
            />:null
          }
          // growth="+2"
          name="Generated Reports"
          value="999"
        />
      </SimpleGrid>
      <SimpleGrid>
        <Projects
          gridArea="1 / 2 / 2 / 2"
          // banner={banner}
          // avatar={avatar}
          name="Adela Parkson"
          job="Product Designer"
          posts="17"
          followers="9.7k"
          following="274"
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap="20px" mb="20px">
        <TotalSpent />
        <WeeklyRevenue />
        <PieCard />
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
          <Tasks />
          <MiniCalendar h="100%" minW="100%" selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
