/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// Chakra imports
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  useColorMode,
  Icon,
  Input,
  InputGroup,
  Link,
  InputRightElement,
  Text,
  useColorModeValue,
  InputLeftAddon,
  VStack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  HStack,
} from "@chakra-ui/react";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
import CButton from "components/fields/Button";
import Upload from "views/admin/company/components/Upload";
import Select from "components/fields/Select";

import { useAuth } from "contexts/AuthContext";

// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Card from "components/card/Card";
import { any } from "store/localActions";
import { FaSadCry } from "react-icons/fa";

function NewCustomer() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const brandColor = useColorModeValue("brand.400", "white");
  var noCompanyExpert = true;

  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const { colorMode } = useColorMode();

  //------------------

  const { getCustomers } = useAuth();
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [customer_phone, setCustomer_phone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [base64Image, setBase64Image] = useState("");
  const inputFileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [customers, setCustomers] = useState(null);
  const userState = useSelector((state) => state);
  const [selectedItem, setSelectedItem] = useState(null);
  const [parent, setParent] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const steps = [
    { title: "Customer Info", description: "" },
    { title: "Project Details", description: "" },
    { title: "Inspection", description: "" },
  ];

  const { activeStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  useEffect(() => {
    const getCustomer = async (e) => {
      const customers_data = await getCustomers();
      setCustomers(customers_data.customers);
      const parent = new URLSearchParams(window.location.search).get("parent");
      if (parent == "list") setParent(true);
    };

    getCustomer();
  }, []);

  const handleAddProject = async (e) => {
    e.preventDefault();

    var _error = false;

    // if (!selectedItem) {
    //   if (first_name == "") _error = true;
    //   if (last_name == "") _error = true;
    //   if (customer_phone == "") _error = true;
    //   if (email == "") _error = true;
    // } else _error = false;

    // setError(_error);

    if (!_error) {
      var customer = {
        first_name: first_name,
        last_name: last_name,
        phone: customer_phone,
        email: email,
        customer_id: selectedItem ? selectedItem : null,
      };

      dispatch(any(customer));

      if (parent) {navigate("/admin/customers");
      }
      else navigate("/onboarding/new-project");
    }
    
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  const handleSelectChange = (event) => {
    setSelectedItem(event.target.value); // Update the state with the selected value
    if(event.target.value)
      setError(false);
  };

  //------------------

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {!parent ? (
        <Flex
          ma
          ml={{ base: "20px", lg: "50px" }}
          mt={{ base: "0px", lg: "20px" }}
          style={{ alignItems: "flex-end" }}
        >
          <Link href="/">
            <Icon
              as={MdArrowBackIosNew}
              w="20px"
              h="20px"
              color="#000"
              marginRight={5}
            />
          </Link>

          <Text fontWeight={500} fontSize={22}>
            Add Customer
          </Text>
        </Flex>
      ) : null}

      <Flex
        //  w={{ base: "100%", md: "800px" }}

        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        // h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "10px", md: "20px" }}
        px={{ base: "0px", md: "0px" }}
        mt={{ base: "20px", md: "3vh" }}
        flexDirection="column"
      >
        {!parent ? (
          <Card
            marginTop="0"
            mb="20px"
            w={{ base: "95%", md: "800px" }}
            mx={{ base: "10px", md: "60px" }}
          >
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <VStack>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>
                  </VStack>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
          </Card>
        ) : null}
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "800px" }}
          maxW="100%"
          background={colorMode === "dark" ? "gray.700" : "white"}
          p="30px"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Box me="auto">
            <Text color={textColor} fontSize="28px" mb="10px">
              Add Customer
            </Text>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Enter customer details below.
            </Text>
          </Box>
          <form onSubmit={handleAddProject}>
            <VStack spacing={3} alignItems="start">
              <Flex width="100%" alignContent="space-between">
                <VStack align="start" spacing={2} marginRight={5}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="0px"
                    htmlFor="first_name"
                    isDisabled={selectedItem}
                  >
                    First Name <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Write customer first name"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    borderRadius="10px"
                    borderColor={error ? "red" : "secondaryGray.100"}
                    value={first_name}
                    w={{ base: "100%", lg: "300px" }}
                    id="first_name"
                    name="first_name"
                    isDisabled={selectedItem}
                    onChange={(e) => setFirst_name(e.target.value)}
                  />
                  {error ? (
                    <Text fontSize={"12px"} color={"red"} mt={"5px"}>
                      Required
                    </Text>
                  ) : null}
                </VStack>
                <VStack align="start" spacing={2}>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="0px"
                    htmlFor="last_name"
                  >
                    Last Name <Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    isDisabled={selectedItem}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Write customer last name"
                    mb="24px"
                    borderColor={error ? "red" : "secondaryGray.100"}
                    fontWeight="500"
                    w={{ base: "100%", lg: "300px" }}
                    size="lg"
                    borderRadius="10px"
                    value={last_name}
                    id="last_name"
                    name="last_name"
                    onChange={(e) => setLast_name(e.target.value)}
                  />
                  {error ? (
                    <Text fontSize={"12px"} color={"red"} mt={"5px"}>
                      Required
                    </Text>
                  ) : null}
                </VStack>
              </Flex>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="customer_phone"
              >
                Phone Number <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup>
                <InputLeftAddon>+1</InputLeftAddon>
                <Input
                  type="tel"
                  placeholder="(000) 000 0000"
                  value={customer_phone}
                  id="customer_phone"
                  isRequired={true}
                  borderColor={error ? "red" : "secondaryGray.100"}
                  name="customer_phone"
                  isDisabled={selectedItem}
                  onChange={(e) => setCustomer_phone(e.target.value)}
                />
              </InputGroup>
              {error ? (
                <Text fontSize={"12px"} color={"red"} mt={"5px"}>
                  Required
                </Text>
              ) : null}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
                htmlFor="email"
              >
                Email <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                isDisabled={selectedItem}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="ex: name@company.com"
                mb="24px"
                borderColor={error ? "red" : "secondaryGray.100"}
                mt="10px"
                fontWeight="500"
                size="lg"
                borderRadius="10px"
                value={email}
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {error ? (
                <Text fontSize={"12px"} color={"red"} mt={"5px"}>
                  Required
                </Text>
              ) : null}

              {!parent && customers && customers.length > 0 ? (
                <Flex align="center" my="45px" width={"100%"}>
                  <HSeparator />
                  <Text fontSize={18} color="gray.400" mx="20px">
                    or
                  </Text>
                  <HSeparator />
                </Flex>
              ) : null}

              {!parent && customers && customers.length > 0 ? (
                <Box width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                    htmlFor="username"
                  >
                    <VStack alignItems="start">
                      <Text>Choose a Customer</Text>
                    </VStack>
                  </FormLabel>

                  <Box justifyContent="space-between" width="100%">
                    <Select
                      w={{ base: "100%", gl: "700px" }}
                      textColor={textColor}
                      value={selectedItem}
                      size="lg"
                      onChange={handleSelectChange}
                    >
                      {customers.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.first_name + " " + item.last_name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Box>
              ) : null}
            </VStack>

            <HStack mt={10}>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="30%"
                h="50"
                type="button"
                bgColor="#fff"
                textColor="#203764"
                border="solid .5px #203764"
                onClick={handleCancelClick}
              >
                Cancel
              </CButton>
              <CButton
                fontSize="base"
                variant="brand"
                fontWeight="500"
                w="70%"
                h="50"
                type="submit"
                bgColor="#203764"
                textColor="#fff"
                // onClick={handleAddProject}
              >
                Save and Continue
              </CButton>
            </HStack>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default NewCustomer;
