import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";



import {
  Box,
  SimpleGrid,
  FormControl,
  InputLeftElement,
  FormLabel,
  HStack,
  VStack,
  Text,
  Flex,
  Stack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Spinner,
  textDecoration,
  Center,
  InputGroup,
  Badge,
} from "@chakra-ui/react";
import { MdLocationPin, MdAdd } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import Input from "components/fields/InputField";
import Select from "components/fields/Select";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";
import Button from "components/fields/Button";
import { useAuth } from "contexts/AuthContext";
import { FaSadCry } from "react-icons/fa";
import Project from "views/admin/projects/components/Project";
import { any } from "store/localActions";



export default function Projects() {
  const { getProjects } = useAuth();
  const [projects, setProjects] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const textColor = useColorModeValue("navy.700", "white");
  const dispatch = useDispatch();
  let secondaryText = useColorModeValue("gray.700", "white");

    const navigate = useNavigate();
     const cardShadow = useColorModeValue(
       "0px 2px 2px rgba(112, 144, 176, 0.12)",
       "unset"
     );


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  useEffect(() => {
    const _getProjects = async (e) => {
      const all_projects = await getProjects();
      var _projects = all_projects.projects;
      dispatch(any(all_projects.projects));
      setProjects(_projects);
      setSpinner(false);
    };

    _getProjects();
  }, []);


  const handleCreateNewProject = () => {
    navigate("/onboarding/new-customer"); // internal route
  };

  const handleProjectDetails = (id) => {
    navigate("/admin/project-details?projectId=" + id);
  };

  var isMobile = false;
  if (window.innerWidth < 768) isMobile = true;

  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Projects
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          leftIcon={<MdAdd />}
          variant="brand"
          onClick={handleCreateNewProject}
        >
          {!isMobile ? "Create New Project" : ""}
        </Button>
      </Box>

      <Flex alignItems="start" width="50%" mt="50px">
        <FormControl>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" boxSize={6} mt={5} ml={3} />
            </InputLeftElement>
            <Input
              isRequired={true}
              bgColor={"white"}
              variant="auth"
              fontSize="sm"
              textColor={textColor}
              ms={{ base: "0px", md: "0px" }}
              paddingLeft={"45px"}
              type="email"
              placeholder="Search"
              fontWeight="500"
              size="lg"
              borderRadius="10px"
              w="460px"
            />
          </InputGroup>
        </FormControl>
        <Select width="200px" bgColor={"white"} textColor={textColor}></Select>
      </Flex>

      {/* <ComplexTable columnsData={columnsProjects} tableData={projects} /> */}

      <Box
        overflowX="auto"
        display={{ base: "none", md: "block" }}
        mt={10}
        p={0}
        borderRadius={15}
        backgroundColor="white"
        boxShadow="sm"
      >
        <TableContainer>
          <Table
            // layout="fixed"
            variant="simple"
            colorScheme="brand"
            size={{ base: "sm", md: "md", lg: "md" }}
          >
            <Thead>
              <Tr>
                <Th bg="#EBEFFF" textColor={textColor}>
                  Name
                </Th>
                {/* <Th bg="#EBEFFF" textColor={textColor}>
                  Address
                </Th> */}
                <Th
                  bg="#EBEFFF"
                  textColor={textColor}
                  wordBreak="break-word"
                  textAlign={"center"}
                >
                  EEEs
                  <Text>Count</Text>
                </Th>
                <Th textAlign={"center"} bg="#EBEFFF" textColor={textColor}>
                  Inspection
                  <Text>Status</Text>
                </Th>
                <Th bg="#EBEFFF" textColor={textColor}>
                  Inspection
                  <Text>Date</Text>
                </Th>
                <Th bg="#EBEFFF" textColor={textColor}>
                  Expert
                </Th>
                <Th bg="#EBEFFF" textColor={textColor}>
                  Customer
                </Th>
                <Th bg="#EBEFFF" textColor={textColor}></Th>
              </Tr>
            </Thead>
            <Tbody>
              {!spinner ? (
                projects.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      <Text fontWeight={"bold"}> {item.name}</Text>
                      <Flex
                        overflowWrap="break-word"
                        wordBreak="break-word"
                        whiteSpace="normal"
                        alignItems="center"
                        alignContent="start"
                      >
                        <MdLocationPin size="16px" />
                        <Text
                          fontSize={"14px"}
                          mx="10px"
                          maxW="200px"
                          marginTop="8px"
                          textColor={secondaryText}
                          display="flex"
                          alignItems="center"
                        >
                          {item.address}
                        </Text>
                      </Flex>
                    </Td>
                    {/* <Td>{item.address}</Td> */}
                    <Td textAlign={"center"}>0</Td>
                    <Td textAlign={"center"}>
                      <ProjectStatusBadge status={item.status} />
                    </Td>
                    <Td>{formatDate(item.created_at)}</Td>
                    <Td>{item.expert.name}</Td>
                    <Td>
                      {item.customer.first_name + " " + item.customer.last_name}
                    </Td>
                    <Td>
                      <Button
                        key={item.id}
                        onClick={() => handleProjectDetails(item.id)}
                        href="#"
                        style={{ textDecoration: "underline" }}
                      >
                        View Details {">"}
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Box m={10} textAlign="center">
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color={textColor}
                    size="lg"
                  />
                </Box>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        <Stack spacing={4}>
          {projects.map((project) => (
            <Project
              boxShadow={cardShadow}
              mb="10px"
              address={project.address}
              update={formatDate(project.updated_at)}
              title={project.name}
              status={project.status}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
