// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Image,
  Badge,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import ProjectStatusBadge from "components/card/ProjectStatusBadge";

import React from "react";
// Assets
import { MdEdit } from "react-icons/md";

export default function Project(props) {
  const { title, status, address, update, expert, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        {/* <Image h='80px' w='80px' src={image} borderRadius='8px' me='20px' /> */}
        <Box mt={{ base: "10px", md: "0" }} width="100%">
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            mb="10px"
          >
            <Text
              color={textColorPrimary}
              fontWeight="900"
              fontSize="md"
              mb="4px"
            >
              {title}
            </Text>
            <ProjectStatusBadge status={status} />
          </Box>
          <Flex>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              Address:
            </Text>
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              {address}
            </Text>
          </Flex>
          <Flex>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              Expert:
            </Text>
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              {expert}
            </Text>
          </Flex>
          <Flex>
            <Text
              fontWeight="bold"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              Update:
            </Text>
            <Text
              fontWeight="500"
              color={textColorPrimary}
              fontSize="sm"
              me="4px"
              my="5px"
            >
              {update}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Card>
  );
}
