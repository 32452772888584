// Chakra imports
import {
  Badge
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Button from "components/fields/Button";
import { MdAdd, MdNotificationsActive } from "react-icons/md";


// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { Navigate, useNavigate } from "react-router-dom";

export default function ProjectStatusBadge(props) {

  const { status } = props;


  return (
    <Badge
      borderRadius={"5px"}
      backgroundColor={
        status === "Inspection"
          ? "#FFEECA"
          : status === "Evaluation"
          ? "#EBEFFF"
          : status === "pending"
          ? "#FEECEE"
          : status === "Complete"
          ? "#E0FCFF"
          : null
      }
      textColor={
        status === "Inspection"
          ? "#6B4F00"
          : status === "Evaluation"
          ? "#295BFF"
          : status === "pending"
          ? "#F2415A"
          : status === "Complete"
          ? "#27BE69"
          : null
      }
    >
      {" "}
      {status}
    </Badge>
  );
}
