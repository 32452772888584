import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";


import {
  Box,
  SimpleGrid,
  FormControl,
  InputLeftElement,
  
  FormLabel,
  HStack,
  VStack,
  Text,
  Flex,
  Stack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useColorModeValue,
  Spinner,
  textDecoration,
  Center,
  InputGroup,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";

import DevelopmentTable from "views/admin/projects/components/DevelopmentTable";
import CheckTable from "views/admin/projects/components/CheckTable";
import ColumnsTable from "views/admin/projects/components/ColumnsTable";
import ComplexTable from "views/admin/projects/components/ComplexTable";
import { columnsProjects } from "views/admin/projects/variables/columnsData";
import tableDataDevelopment from "views/admin/projects/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/projects/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/projects/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/projects/variables/tableDataComplex.json";
import Input from "components/fields/InputField";
import Select from "components/fields/Select";
import Button from "components/fields/Button";
import { useAuth } from "contexts/AuthContext";
import { FaSadCry } from "react-icons/fa";

export default function Experts() {
  const { getexperts } = useAuth();
  const [spinner, setSpinner] = useState(true);
  const textColor = useColorModeValue("navy.700", "white");
  const navigate = useNavigate();
  const [experts, setExperts] = useState([]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  useEffect(() => {
   const getExperts = async (e) => {
     const experts_data = await getexperts();
     setExperts(experts_data);
   };
   getExperts();
   setSpinner(false);
  }, [useState]);

  const handleInviteExpert = () => {
    navigate("/onboarding/inviteexperts?parent=list"); // internal route
  };

  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          Experts
        </Heading>
        <Button
          bgColor="#203764"
          textColor="#fff"
          leftIcon={<MdAdd />}
          variant="brand"
          onClick={handleInviteExpert}
        >
          Invite Expert
        </Button>
      </Box>

      <Flex alignItems="start" width="50%" mt="50px">
        <FormControl>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" boxSize={6} mt={5} ml={3} />
            </InputLeftElement>
            <Input
              isRequired={true}
              bgColor={"white"}
              variant="auth"
              fontSize="sm"
              textColor={textColor}
              ms={{ base: "0px", md: "0px" }}
              paddingLeft={"45px"}
              type="email"
              placeholder="Search"
              fontWeight="500"
              size="lg"
              borderRadius="10px"
              w="460px"
            />
          </InputGroup>
        </FormControl>
        <Select width="200px" bgColor={"white"} textColor={textColor}></Select>
      </Flex>

      {/* <ComplexTable columnsData={columnsProjects} tableData={projects} /> */}

      {experts ? (
        <Box>
          <Box
            overflowX="auto"
            display={{ base: "none", md: "block" }}
            mt={10}
            p={0}
            borderRadius={15}
            backgroundColor="white"
            boxShadow="sm"
          >
            <TableContainer>
              <Table
                // layout="fixed"
                variant="simple"
                colorScheme="brand"
                size={{ base: "sm", md: "md", lg: "md" }}
              >
                <Thead>
                  <Tr>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      Expert Name
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}>
                      email
                    </Th>

                    <Th bg="#EBEFFF" textColor={textColor}>
                      Created at
                    </Th>
                    <Th bg="#EBEFFF" textColor={textColor}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {!spinner ? (
                    experts.map((item) => (
                      <Tr
                        key={item.id}
                        sx={{
                          borderBottom: "1px solid black",
                          marginBottom: "10px",
                        }}
                      >
                        <Td py={4}>{item.name}</Td>
                        <Td py={4}>{item.email}</Td>
                        <Td py={4}>{formatDate(item.created_at)}</Td>
                        <Td py={4}>
                          <a href="#" style={{ textDecoration: "underline" }}>
                            View Details >
                          </a>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Box m={10} textAlign="center">
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color={textColor}
                        size="lg"
                      />
                    </Box>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>

          <Box display={{ base: "block", md: "none" }}>
            <Stack spacing={4}>
              {experts.map((item) => (
                <Box
                  key={item.id}
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  p={4}
                  bg="white"
                  boxShadow="md"
                >
                  <Heading size="md">
                    Name: {item.first_name + " " + item.last_name}
                  </Heading>
                  <Text>
                    <strong>Email:</strong> {item.email}
                  </Text>

                  <Text>
                    <strong>Phone:</strong> {item.phone}
                  </Text>
                  <Text>
                    <strong>Inspection Date:</strong>{" "}
                    {formatDate(item.created_at)}
                  </Text>
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
