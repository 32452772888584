// src/pages/LogOffPage.js
import React, { useEffect } from "react";
import { useAuth } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";

  
const LogOffPage = () => {
  const { logoutUser } = useAuth();
  const navigate = useNavigate();
  const userState = useSelector((state) => state);


  useEffect(() => {
    if (userState.auth.user != null) 
      logoutUser();
    else
       navigate("/auth/sign-in");
  }, [logoutUser, navigate]);

  return (
    <Box
      minH="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg="gray.50"
      flexDirection="column"
    >
      <Spinner size="xl" mb={4} />
      <Text>Logging you off...</Text>
    </Box>
  );
};

export default LogOffPage;
