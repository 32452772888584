import { API_URLS } from "config/apiConfig";
import { useSelector, useDispatch } from "react-redux";

export const onboarding_company_service = async (
  name,
  phone_number,
  address,
  logo,
  description,
  token
) => {
  try {

    const formData = new FormData();
    formData.append("logo", logo); 
    formData.append("name", name); 
    formData.append("phone_number", phone_number); 
    formData.append("address", address);
    formData.append("description", description);

    const response = await fetch(API_URLS.onboarding, {
      method: "POST",
      headers: {
        // "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: formData,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Onboarding failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Onboarding error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const invite_expert_service = async (
  emails,
  token
 
) => {
  try {
    const _body = JSON.stringify({
      emails: emails
    });

    const response = await fetch(API_URLS.invite_expert, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Invite failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Onboarding error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const company_expert_service = async (token) => {
  try {
    const _body = "";

    const response = await fetch(API_URLS.company_experts, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch experts failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Fetch experts error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const add_project_service = async (
  project_address,
  project_name,
  expert_id,
  description,
  customer_id,
  first_name,
  last_name,
  phone,
  email,
  token
) => {
  try {
    const _body = JSON.stringify({
      name: project_name,
      address: project_address,
      expert_id: expert_id,
      description: description,
      customer_id: customer_id,
      customer: {
        id: customer_id,
        first_name: first_name,
        last_name: last_name,
        phone: phone,
        email: email,
      },
    });

    const response = await fetch(API_URLS.add_projects, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Add project failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Add project error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const edit_project_service = async (
  projectId,
  project_address,
  project_name,
  expert_id,
  description,
  customer_id,
  token
) => {
  try {
    const _body = JSON.stringify({
      name: project_name,
      address: project_address,
      expert_id: expert_id,
      description: description,
      customer_id: customer_id
    });

    const response = await fetch(API_URLS.edit_projects + projectId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
      body: _body,
    });
    console.log(response.body);

    if (!response.ok) {
      throw new Error("Add project failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Add project error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const company_project_service = async (token) => {
  try {

    const response = await fetch(API_URLS.all_projects, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch experts failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Fetch experts error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};

export const company_customer_service = async (token) => {
  try {
    const response = await fetch(API_URLS.all_customers, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "User-Agent": navigator.userAgent,
        Authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      throw new Error("Fetch customers failed");
    }

    const data = await response.json();
    return data; // Returns the API response (including token)
  } catch (error) {
    console.error("Fetch experts error:", error);
    throw error; // Re-throw error to be handled in AuthContext
  }
};
