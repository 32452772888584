
// store.js

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // LocalStorage
import userReducer from 'store/userReducer';

// Configuration for redux-persist
const persistConfig = {
  key: 'root', // Key for the storage
  storage,    // Storage engine
  whitelist: ['user'], // Optional: list of reducers to persist
};

// Wrap your userReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, userReducer);

// Create the Redux store
const store = configureStore({
  reducer: {
    user: persistedReducer, // Use the persistedReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Turn this off if you store non-serializable data
    }),
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };



// import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "store/userReducer";


// const store = configureStore({
//   reducer: {
//     user: userReducer, 
//   },
// });

// export default store;


