import React, { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import Button from "components/fields/Button";
import { useAuth } from "contexts/AuthContext";
import Card from "components/card/Card";
import Projects from "views/admin/home/components/Projects";

export default function CustomerDetails() {
  const { getCustomers } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [customer, setCustomer] = useState([]);
  const localState = useSelector((state) => state.local);
  const dispatch = useDispatch();
      



  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Format to MM/DD/YYYY
  };

  useEffect(() => {
    var customerId = new URLSearchParams(window.location.search).get(
      "customerId"
    );
    var customers = localState.objects;
    var filter_customers = customers.filter((customer) => customer.id == customerId);
    if (filter_customers) 
      if (filter_customers.length > 0) {
        setCustomer(filter_customers[0]);
        setProjects(filter_customers[0].projects);
      }
    
  }, [useState]);

  const handleCreateNewProject = () => {
    navigate("/onboarding/new-customer?parent=list"); // internal route
  };

  return (
    <Box pt={{ base: "40px", md: "30px", xl: "30px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        mx="20px"
      >
        <Heading as="h1" size="lg">
          {customer.first_name + " " + customer.last_name}
        </Heading>
        {/* <Button
          bgColor="#203764"
          textColor="#fff"
          leftIcon={<MdAdd />}
          variant="brand"
          onClick={handleCreateNewProject}
        >
          Add New Customer
        </Button> */}
      </Box>

      <Card mt="50px" ml="20px" width="90%">
        <Flex display="flex" justifyContent="space-between">
          <Box fontSize={"16px"}>
            <Text fontWeight={"bold"}>Name: </Text>
            <Text>
              {customer.first_name}
              {customer.last_name}
            </Text>
          </Box>
          <Box fontSize={"16px"}>
            <Text fontWeight={"bold"}>Email: </Text>
            <Text>{customer.email}</Text>
          </Box>
          <Box fontSize={"16px"}>
            <Text fontWeight={"bold"}>Phone Number</Text>
            <Text>{customer.phone}</Text>
          </Box>
          <Box fontSize={"16px"}>
            <Button>Edit</Button>
          </Box>
        </Flex>
      </Card>

      <SimpleGrid ml={"20px"}>
        <Projects
          title="Projects"
          gridArea="1 / 2 / 2 / 2"
          projects={projects}
        />
      </SimpleGrid>
    </Box>
  );
}
